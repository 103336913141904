.contactPage {
    height: auto;
    background-image: url('../../../public/assets/homepage/heroSectionBg.png');
    background-repeat: repeat;
    background-position: -150px 0px;
    display: flex;
    flex-direction: column;
    /* justify-content: ; */
    align-items: center;
    margin-top: 20px;
}

.contactContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    width: 80%;
}

.contactFormContainer {
    /* margin-top: 40px; */
    width: 50%;
    height: 600px;
    background-color: white;
    display: flex;
    /* justify-content: center; */
    align-content: center;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 10px;
    /* width: 0%; */
}

.contactFormWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
}


.contactInfoContainer {
    display: flex;
    flex-direction: column;
    height: 480px;
    background-color: white;
    width: auto;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 10px;
    /* justify-content: center; */
}

.contactInfo {
    padding: 50px;
}

.contactInfoTitle {
    /* margin-left: 20px; */
    /* margin-bottom: 50px; */
    font-size: 23px;
    font-weight: 600;

}

.contactInfoMainWrapper {
    margin-top: 20px;
}

.contactInfoWrapper {
    /* margin-left: 20px; */
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-weight: 500;
    letter-spacing: 1px;
}

.contactInfoImage {
    height: 30px;
    margin-right: 14px;
}

.contactInfoPhoneNums {
    display: flex;
    flex-direction: column;
    font-size: 15px;
}

.contactInfoPhoneNum {
    /* margin-bottom: 1px; */
    text-decoration: none;
    color: #0A192F;
}

.contactSubmitButton {
    width: 180px;
    border: none;
    background-color: #0A192F;
    color: white;
    font-size: 20px;
    margin-bottom: 30px;
    height: 50px;
    border-radius: 10px;
    font-size: 23px;
    cursor: pointer;
}

/* Media queries */
@media screen and (max-width: 580px) {
    .contactPage {
        height: auto;
        background-size: cover;
    }

    .contactInfoContainer {
        height: 380px;
        justify-content: center;
    }

    .contactInfo {
        display: flex;
        flex-direction: column;
        padding: 0px;
        justify-content: center;
        align-items: center;
    }

    .contactContainer {
        flex-direction: column;
    }

    .contactFormContainer {
        width: 100%;
        margin-bottom: 30px;
    }

    .contactInfoContainer {
        width: 100%;
    }
}